<template>
  <div>
    <v-container>
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
               class="pa-5">
        <div style="position:relative">
          <v-skeleton-loader height="200px" type="image"></v-skeleton-loader>
          <v-avatar size="120"
                    color="white"
                    style="position: absolute; left: 50%; bottom: -20px; margin-left: -60px"
          ></v-avatar>
        </div>

        <div class="mt-10 mb-5">
          <v-skeleton-loader class="mx-16 px-sm-16"
                             type="text">
          </v-skeleton-loader>
          <v-skeleton-loader class="mx-10 px-sm-10 mt-1"
                             type="text">
          </v-skeleton-loader>
        </div>

        <v-skeleton-loader class="mx-auto my-3" type="divider"></v-skeleton-loader>
        <div class="d-flex">
          <v-skeleton-loader class="mr-3" type="chip"></v-skeleton-loader>
          <v-skeleton-loader class="mr-3" type="chip"></v-skeleton-loader>
          <v-skeleton-loader type="chip"></v-skeleton-loader>
        </div>
        <v-skeleton-loader class="mx-auto mt-3" type="divider" v-bind="attrs"></v-skeleton-loader>

        <v-row>
          <v-col cols="12" sm="8">
            <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line"
                               v-bind="attrs">
            </v-skeleton-loader>
            <v-skeleton-loader class="mx-auto mt-3" type="list-item-avatar-two-line"
                               v-bind="attrs">
            </v-skeleton-loader>
            <v-skeleton-loader class="mx-auto mt-3" type="list-item-avatar-two-line"
                               v-bind="attrs">
            </v-skeleton-loader>
            <v-skeleton-loader class="mx-auto mt-3" type="list-item-avatar-two-line"
                               v-bind="attrs">
            </v-skeleton-loader>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card class="pa-5" flat>
              <v-skeleton-loader type="text" v-bind="attrs"></v-skeleton-loader>
              <div class="d-flex justify-space-between">
                <v-skeleton-loader type="sentences" v-bind="attrs"
                                   width="100px">
                </v-skeleton-loader>
                <v-skeleton-loader type="button"
                                   v-bind="attrs">
                </v-skeleton-loader>
              </div>
              <div class="d-flex justify-space-between">
                <v-skeleton-loader type="sentences" v-bind="attrs"
                                   width="100px">
                </v-skeleton-loader>
                <v-skeleton-loader type="button"
                                   v-bind="attrs">
                </v-skeleton-loader>
              </div>
              <div class="d-flex justify-space-between">
                <v-skeleton-loader type="sentences" v-bind="attrs"
                                   width="100px">
                </v-skeleton-loader>
                <v-skeleton-loader type="button"
                                   v-bind="attrs">
                </v-skeleton-loader>
              </div>
            </v-card>
            <v-skeleton-loader class="mx-auto"
                               type="image">
            </v-skeleton-loader>
            <v-card class="py-5 d-flex justify-center" flat>
              <v-skeleton-loader
                  type="chip">
              </v-skeleton-loader>
            </v-card>
          </v-col>
        </v-row>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "loadingBusiness",
  data: () => ({
    attrs: {
      class: 'mb-6',
    },
  }),
  inject: {
    theme: {
      default: {isDark: false},
    },
  },
}
</script>

<style scoped>

</style>
